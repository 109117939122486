import { getAction, getByIdAction, postAction, putAction } from '@/api/common/request'
const baseURL = 'xun/work/xunLifeLive'
//调用 import {  } from "@/api/life/life/lifeHousing";

const liveList=(params)=>getAction(baseURL+"/list",params);

const getLastLiveApi=(params)=>getAction(baseURL+"/getLastLive",params);


const addLive=(params)=>postAction(baseURL+"/add",params);

const queryById=(params)=>getByIdAction(baseURL+"/queryById",params);

const judgeIsBasicsApi=(params)=>getAction(baseURL+"/judgeIsBasics",params);

const editLive=(params)=>putAction(baseURL+"/edit",params);

export {
    liveList,
    getLastLiveApi,
    addLive,
    queryById,
    editLive,
    judgeIsBasicsApi
}